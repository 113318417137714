const colors = {
  // Theme UI colour names
  text: "#fff",
  background: "#171717",
  primary: "#bd06a1",
  secondary: "#989898",
  accent: "#787878",
  muted: "#737373",

  // Additional colour names
  bgAccent: "rgba(0,0,0,0.1)",
  bgOpaque: "rgba(23,23,23,0.9)"
}

colors.bgGradient = `linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.1))`

export default colors
